<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            dense
            hide-default-footer
            disable-pagination
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Job Date",
          align: "center",
          sortable: false,
          value: "jobDate",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Job Name",
          align: "center",
          sortable: false,
          value: "jobName",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Part Number",
          align: "center",
          sortable: false,
          value: "partNumber",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 6,
          text: "Part Description",
          align: "center",
          sortable: false,
          value: "partDesc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 7,
          text: "Qty",
          align: "center",
          sortable: false,
          value: "qty",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 8,
          text: "UM",
          align: "center",
          sortable: false,
          value: "um",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 9,
          text: "Price",
          align: "center",
          sortable: false,
          value: "price",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 10,
          text: "Total",
          align: "center",
          sortable: false,
          value: "total",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      items: [],
    };
  },
};
</script>

<style></style>
